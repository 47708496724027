import "./operationmessage.css"

export default function OperationMessage({ status, msg, customStyle }) {
  return(
    <div className={"customOperationMessage " +
    `${status === 0 ? "hideOperationMessage" : status === 1 ? "successMessage" : status === 2 ? "errorMessage" : ""}`}
    style={customStyle}>
      <span className="operationMessageWrapper">{msg}</span>
    </div>
  )
}