import "./tabletab.css"

export default function TableTab(props) {
  let options = props.options.map((opt) => 
    <div 
      className={`tableTab${(props.selector === opt.trigger) ? " selected" : ""}`} 
      onClick={() => { handleSelect(opt.trigger) }}
      key={opt.trigger}
    >
      {opt.label}
    </div>
  )

  function handleSelect(callerTrigger) {
    props.setSelector(callerTrigger);
  }

  return (
    <div className="tableTabsFlex">
      {options}
    </div>
  )
}