import {useEffect, useState} from 'react';
import './errorpanel.css';
import Button from '../Utilities/Button/button'
import pcLogo from '../../assets/images/pc-apotek-logo.svg';

export default function LoginPanel(props) {

  let [formSpacerHeight, setFormSpacerHeight] = useState(0);

  useEffect(() => {
    recalculateSpacer();
    window.addEventListener('resize', recalculateSpacer)

    return () => {
      window.removeEventListener('resize', recalculateSpacer);
    }
  }, [])

  function recalculateSpacer() {
    setFormSpacerHeight(
      (((window.innerHeight - document.querySelector(".error-BoxWrapper").clientHeight) / 2) >= 12) ? 
      `${((window.innerHeight - document.querySelector(".error-BoxWrapper").clientHeight) / 2)}px` :
      '12px'
      )
  }

  function handleRefreshClick(setting) {
    if (setting === 1) {
      props.setCurrentUser((prevUserState) => {
        let newUserObject = {
          ...prevUserState,
          auth: 1
        }
        delete newUserObject.err;
        return newUserObject;
      });
    } else if (setting === 2) {
      props.resetUser();
    }
  }

  let errorHeading, errorElement;

  if (props.error === "functions-unavailable") {
    errorHeading = "Functions Server Unavailable"
    errorElement = 
    <div>
      <p className='error-text'>
        Please contact the system administrator and inform the shown error for further support.
      </p>
      <p className='error-text'><i>
        Mohon untuk menghubungi administrator sistem dan tunjukkan error untuk dukungan lebih lanjut.
      </i></p>
      <p className='error-contact'>WhatsApp: +62 8998010500</p>
      <div style={{ display: "flex", gap: "0.5em", marginTop: "3em"}}>
        <Button iconClasses="bi bi-arrow-clockwise" btnText="Coba Ulang" clickHandler={() => handleRefreshClick(1)} type="button" />
        <Button iconClasses="bi bi-box-arrow-left" btnText="Logout" clickHandler={() => handleRefreshClick(2)} type="button" />
      </div>
    </div>

  } else if (props.error === "no-permissions") {
    errorHeading = "Insufficient User Permissions"
    errorElement = 
    <div>
      <p className='error-text'>
        Please contact the system administrator and inform the shown error for further support.
      </p>
      <p className='error-text'><i>
        Mohon untuk menghubungi administrator sistem dan tunjukkan error untuk dukungan lebih lanjut.
      </i></p>
      <p className='error-contact'>WhatsApp: +62 8998010500</p>
      <div style={{ display: "flex", gap: "0.5em", marginTop: "3em"}}>
        <Button iconClasses="bi bi-arrow-clockwise" btnText="Coba Ulang" clickHandler={() => handleRefreshClick(1)} type="button" />
        <Button iconClasses="bi bi-box-arrow-left" btnText="Logout" clickHandler={() => handleRefreshClick(2)} type="button" />
      </div>
    </div>
  }

  return (
    <div className='errorPage'>
      <div style={{height: `${formSpacerHeight}`}}></div>
      <div className='error-BoxWrapper'>

        <img src={pcLogo} />
        <h3>
          Critical Error!<br />
          {errorHeading}
        </h3>

        {errorElement}

      </div>
      <div style={{height: `${formSpacerHeight}`}}></div>
    </div>
  );
}