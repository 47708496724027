import { useContext } from "react";
import { SidebarContext } from "../Contexts";

export default function SidebarOption(props) {
  const [sbStatus, activeTab, handleNewActive, openSideBar, currentDD, handleNewDD] = useContext(SidebarContext);
  let dropdownOptionsList = [];
  let ddOptionLH = 0;
  let isDDActive = JSON.stringify(currentDD) == JSON.stringify(props.tabNo);

  function handleClick(caller) {
    if (caller.length > 1) {
      if (sbStatus == "open") {
        handleNewDD(props.tabNo)
      } else {
        openSideBar()
        handleNewDD(props.tabNo)
      }
    } else {
      handleNewActive(caller[0])
      if (props.dropdown) handleNewDD(null);
    };
  }

  if (props.dropdown != undefined) {
    ddOptionLH = parseInt(getComputedStyle(document.querySelector('.dropdownContainer')).lineHeight.slice(0, -2));;
    
    dropdownOptionsList = props.dropdown.filter(nullFilter => nullFilter).map((ddOption) => {
      return (
        <div key={ddOption.tabNo[0]} className="dd-indivOption" onMouseDown={() => {handleClick(ddOption.tabNo)}}>{ddOption.name}</div>
      )
    })
  }

  return (
    <div className="sidebar-mt">

      <div className={`sidebarOption ${(props.tabNo.includes(activeTab)) ? 'active' : ''}`} onMouseDown={() => {handleClick(props.tabNo)}}>
        <div className="icon"><i className={props.iconClass}></i></div>
        <div className="optionName">{props.name}</div>
        {props.dropdown && 
          <div className="dropdownIcon" style={{marginLeft: "auto"}}>
            <i className={`bi bi-chevron-down ${(isDDActive) ? 'flip' : ''}`}></i>
          </div>
        }
      </div>

      {props.dropdown && 
        <div 
          style={(isDDActive) ? {height: `calc(${props.dropdown.filter(nullFilter => nullFilter).length} * ${ddOptionLH}px + 2em)`} : {}} 
          className={`dropdownContainer ${(isDDActive) ? "ddExpand" : ""}`}
        >
          {dropdownOptionsList}
        </div>
      }
    </div>

  );
};