import TableRow from "./tablerow";
import "./table.css";
import { useEffect, useState } from "react";

export default function Table({
  dataOnly = false,
  xScroll = false,
  yScroll = false,
  mw,
  dataArray = [],
  format = [],
  ending
}) {
  const [mappedRows, setMappedRows] = useState([]);

  useEffect(() => {
    setMappedRows(
      (dataArray.length > 0) ?
      dataArray.map((dataObject, idx) => {
        let finalColumns = [];
    
        for (let i = 0; i < format.length; i++) {
          finalColumns.push([format[i][2], dataObject[format[i][1]]])
        }
    
        return <TableRow columns={finalColumns} key={dataObject.key ? dataObject.key : idx} />
      }) : <div className="noDataError"><i>Tidak ada data untuk tabel ini.</i></div>
    );
  }, [dataArray])

  return xScroll ? (
    <div className={`customTable${dataOnly ? ' dataOnly' : ""}`} style={{ minWidth: mw ? mw : "inherit" }}>
      <div className={`x-scroll-wrapper${yScroll ? " tableScroll" : ""}`}>
        <div className={`customTable dataOnly`}>
          <TableRow columns={format.map((columnArray) => [columnArray[2], columnArray[0]])} key={"purchasesProductsHeader"} />
          {mappedRows} 
          {ending &&
          <>
            <hr style={{ marginTop: "-1px" }} />
            <TableRow columns={ending} ending="true" key={"purchasesProductsFooter"} />
          </>
          }
        </div>
      </div>
    </div>
  ) : (
    <div className={`customTable${dataOnly ? ' dataOnly': ''}${yScroll ? " tableScroll" : ""}`} style={{ minWidth: mw ? mw : "inherit" }}>
      <TableRow columns={format.map((columnArray) => [columnArray[2], columnArray[0]])} key={"purchasesProductsHeader"} />
      {mappedRows} 
      {ending &&
      <>
        <hr style={{ marginTop: "-1px" }} />
        <TableRow columns={ending} ending="true" key={"purchasesProductsFooter"} />
      </>
      }
      
    </div>
  )
}