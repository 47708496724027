import "./toggler.css"

export default function Toggler(props) {
  let mappedButtons = props.options.map((option) => {
    return (
    <button 
      key={`toggler-${Math.random()}`}
      type="button" 
      style={props.style}
      onClick={option[2]} 
      className={`${option[1] ? 'selected ' : ''}${props.optionsClass ? props.optionsClass : ''}`}
    >
      {option[0]}
    </button>
    )
  })
  return (
    <div 
      className={`customToggler ${props.className ? props.className : ""}`} 
      style={{ minWidth: "max-content" }}
    >
      {mappedButtons}
    </div>
  )
}