import "./dropdown.css";

export default function Dropdown({ 
  selectorFn = () => {},
  selected,
  selectorText, 
  content,
  expandDirection = "center",
  expandOffset = "0px"
}) {
  // const thisDropdown 
  console.log(typeof(selectorText))
  const alignmentStyle = 
    expandDirection === "center" ? {
      left: "50%",
      transform: `translateX(calc(-50% + ${expandOffset}))`,
    } : expandDirection === "left" ? {
      right: "0%",
      transform: `translateX(${expandOffset})`,
    } : {
      left: "0%",
      transform: `translateX(${expandOffset})`,
    }

  return (
    <div className={`Dropdown ${selected ? "selected" : ""}`}>
      <div
        className="selector"
        onClick={selectorFn}
      >{selectorText}</div>
      <div 
        className={`content-wrapper`}
        style={alignmentStyle}
        onMouseLeave={selectorFn}
      >
        {content}
      </div>
      </div>
  );
}