import { useContext } from "react";
import { UserContext } from "../../Contexts";
import "./beranda.css";

export default function Beranda() {
  const [user] = useContext(UserContext)

  return (
    <div className="beranda">
      <h3 className="greetings">Halo, {user.displayName.split(" ")[0]} 👋</h3>
    </div>
  );
}