import { useContext, useEffect, useRef, useState } from "react";
import { useFunctionsFetch, findMatchesArrayObject, formatCurrency } from "../../UtilFunctions";

import TambahBarang from "./tambahproduk";
import Table from "../../Utilities/Table/table";
import "./kelolaproduk.css";
import Button from "../../Utilities/Button/button";
import Toggler from "../../Utilities/Toggler/toggler";
import PopupWindow from "../../Utilities/PopupWindow/popupwindow";
import { OptionsContext, PermissionsContext, UserContext } from "../../Contexts";
import { useReactToPrint } from "react-to-print";
import Dropdown from "../../Utilities/Dropdown/dropdown";

export default function KelolaBarang() {
  // Get Options Binding
  const optionsBinding = useContext(OptionsContext);
  const userBinding = useContext(UserContext)[0];
  const permsBinding = useContext(PermissionsContext);

  // Show Add Product Window
  const [addProductWindow, setAddProductWindow] = useState(false);

  // Get Products Hooks
  const [callGetItemsArray, setCallGetItemsArray] = useState(1);
  function refreshProductList() { setCallGetItemsArray((prev) => { return prev + 1; }); }
  useFunctionsFetch([], "/get-products", { "query": {}, "projection": {} }, optionsBinding["manage-items"], callGetItemsArray, updateProcessedData);

  // Update Product Hooks
  const [updateBody, setUpdateBody] = useState({})
  const [callPushItemUpdate, setCallPushItemUpdate] = useState(0);
  useFunctionsFetch(undefined, "/modify-item", updateBody, optionsBinding["manage-items"], callPushItemUpdate, () => {
    setCallPushItemUpdate((prev) => { return prev - 1; })
    refreshProductList();
  })

  // Search Products Input State
  const [searchProductsText, setSearchProductsText] = useState("");

  // Edit Product States
  const [editProductState, setEditProductState] = useState([0, {}]);
  const [editProductPrice, setEditProductPrice] = useState({
    s_price: "",
    format_s_price: "",
    s_price_b: "",
    format_s_price_b: "",
  })

  // Edit Price Functionality
  function openEditPrice(item) {
    setEditProductPrice({
      s_price: item.s_price,
      format_s_price: formatCurrency(item.s_price),
      s_price_b: item.s_price_b ? item.s_price_b : "",
      format_s_price_b: formatCurrency(item.s_price_b ? item.s_price_b : ""),
    });
    setEditProductState([1, {
      ...item,
      format_p_name: `${item.p_name} (${item.qty_pack} ${item.unit} / ${item.packaging}) [${item.category}]`
    }])
  }

  function handleEditFormChange(changeEvt) {
    if (!(/^([1-9][\d\,]*)?$/.test(changeEvt.target.value))) {
      return
    } 

    let newValue = changeEvt.target.value.replaceAll(",", "")
    setEditProductPrice((prev) => ({
      ...prev,
      [changeEvt.target.name]: parseInt(newValue),
      [`format_${changeEvt.target.name}`]: formatCurrency(newValue)
    }))
  }

  function confirmEditPrice(submitEvt) {
    submitEvt.preventDefault()
    setUpdateBody({
      query: {
        pid: editProductState[1].pid
      },
      updates: {
        $set: {
          s_price: parseInt(editProductPrice.s_price),
          s_price_b: parseInt(editProductPrice.s_price_b)
        }
      }
    })
    setCallPushItemUpdate((prev) => { return prev + 1; })
    setEditProductState([0, {}]);
  }

  // Product List State
  const [processedDataArray, setProcessedDataArray] = useState([]);
  const [shownDataArray, setShownDataArray] = useState([]);
  const [unlimitedDataArray, setUnlimitedDataArray] = useState([]);
  const [availDataCount, setAvailDataCount] = useState(0);
  const [processedDataFilters, setProcessedDataFilters] = useState([1, 250, 0, 0]);
  const [currentTooltip, setCurrentTooltip] = useState(null);

  function setEnableFilter(newvalue) { setProcessedDataFilters((old) => [newvalue, old[1], old[2], 0]); }
  function setAmountFilter(newvalue) { setProcessedDataFilters((old) => [old[0], newvalue, old[2], 0]); }
  function setSortFilter(newvalue) { setProcessedDataFilters((old) => [old[0], old[1], newvalue, 0]); }

  function updateProcessedData(newData) {
    let processedData = newData.map(item => {
      // Format data for table presentation
      return {
        ...item,
        
        format_p_name: `${item.p_name} (${item.qty_pack} ${item.unit} / ${item.packaging}) [${item.category}]`,
        ed: (item.rem_stocks.length > 0) ? 
        (() => {
          let splitED = item.rem_stocks[0].expirydate.split("-")
          return `${splitED[2]}/${splitED[1]}/${splitED[0]}`
        })()
        : "—",
        
        s_price_display: item.s_price_b ?
        `Rp ${formatCurrency(item.s_price)} (${formatCurrency(item.s_price_b)}) / ${item.packaging}`
        : `Rp ${formatCurrency(item.s_price)} / ${item.packaging}`,
        
        bprice: (item.rem_stocks.length > 0) ? 
        (() => {
          return `Rp ${formatCurrency(Math.round((item.av_p_price * item.qty_pack)))} / ${item.packaging}`;
        })()
        : `Rp —`,
        
        stock: (item.rem_stocks.length > 0) ? 
        (() => {
          let totalStock = item.rem_stocks.map((stock) => (stock.remaining_qty));
          totalStock = totalStock.reduce((prev, current) => prev + current, 0);
          let remaining = totalStock % item.qty_pack
          if (remaining === 0) return `${totalStock / item.qty_pack} ${item.packaging}`;
          else {
            let intPackaging = Math.floor(totalStock / item.qty_pack);
            if (intPackaging === 0) return `${remaining} ${item.unit}`;
            else return `${intPackaging} ${item.packaging} + ${remaining}`;
          }
        })()
        : `0 ${item.packaging}`
      }
    });
    
    setProcessedDataArray(processedData);
  }
  
  // Toggle Enable Functionality
  function toggleEnable(productID, currentStatus) {
    setUpdateBody({
      query: {
        pid: productID
      },
      updates: {
        $set: {
          enabled: !currentStatus
        }
      }
    });
    setCallPushItemUpdate((prev) => { return prev + 1; });
  }

  // Search Products Functionality
  const [searchBy, setSearchBy] = useState("format_p_name")
  function updateShownArray() {
    let preShownArray = findMatchesArrayObject(
      searchProductsText.split(" "), 
      processedDataArray, 
      searchBy);
    if (processedDataFilters[0] === 0) {
      preShownArray = preShownArray.filter((listing) => { return listing.enabled === false })
    } else if (processedDataFilters[0] === 1) {
      preShownArray = preShownArray.filter((listing) => { return listing.enabled === true })
    }
    
    let sortedData;
    if (processedDataFilters[2] === 0) {
      sortedData = preShownArray.sort((a, b) => {
        if (a.pid < b.pid) { return -1; }
        else if (a.pid > b.pid) { return 1; }
        else return 0;
      })
    } else if (processedDataFilters[2] === 1) {
      sortedData = preShownArray.sort((a, b) => {
        if (a.p_name < b.p_name) { return -1; }
        else if (a.p_name > b.p_name) { return 1; }
        else return 0;
      })
    } else if (processedDataFilters[2] === 2) {
      sortedData = preShownArray.sort((a, b) => {
        let aED = a.ed.split("/")
        let bED = b.ed.split("/")
        aED.reverse();
        bED.reverse();
        if (aED < bED) { return -1; }
        else if (aED > bED) { return 1; }
        else return 0;
      })
    } else if (processedDataFilters[2] === 3) {
      let tempArr = preShownArray.map((current) => {
        let stocks = current.rem_stocks.map(a => a.remaining_qty)
        return {
          ...current,
          "qty_sort": stocks.reduce((a, b) => a + b, 0) / current.qty_pack
        }
      })
      sortedData = tempArr.sort((a, b) => {
        if (a.qty_sort < b.qty_sort) { return -1; }
        else if (a.qty_sort > b.qty_sort) { return 1; }
        else return 0;
      })
    }

    setAvailDataCount(sortedData.length);
    setUnlimitedDataArray(sortedData);
    sortedData = sortedData.slice(processedDataFilters[1] * processedDataFilters[3], processedDataFilters[1] * (processedDataFilters[3] + 1));
    setShownDataArray(sortedData);
  }

  useEffect(() => {
    updateShownArray()
  }, [searchProductsText, processedDataArray, processedDataFilters, searchBy])

  let finalArr = shownDataArray.map((item) => ({
    ...item, 
    mgtBtn: 
    <div style={{ fontSize: "20px", position: "relative" }}>
      {currentTooltip && (currentTooltip.pid === item.pid) && 
        <div className="item-tooltip">
          <p className="tooltip-title">Ingredients</p>
          <p className="tooltip-content">{item.ingredients}</p>
        </div>
      }
      <i 
        className="bi bi-info-square" 
        style={{ position:"relative", fontSize: "18.3px", top: "-1px" }}
        onMouseEnter={() => { setCurrentTooltip(item); }}
        onMouseLeave={() => { setCurrentTooltip(null); }}
      ></i>
      {userBinding.perms.includes(permsBinding["view-product-details"]) && <>
        <i className="bi bi-pencil-square" style={{ marginLeft: "0.4em", marginRight: "0.4em" }} onClick={() => {openEditPrice(item)}}></i>
        {item.enabled ? 
        <i className="bi bi-toggle-on" style={{ fontSize: "24px" }} onClick={() => {toggleEnable(item.pid, true)}}></i>
        : <i className="bi bi-toggle-off" style={{ fontSize: "24px" }} onClick={() => {toggleEnable(item.pid, false)}}></i>}
      </>}

    </div>
  }))

  // Dropdown Print and Settings Functionality
  const [currentDropdown, setCurrentDropdown] = useState(null);
  
  // Print Stock Functionality
  const printStockRefLimited = useRef();
  const printStockLimited = useReactToPrint({
    content: () => printStockRefLimited.current,
  });
  const printStockRefUnlimited = useRef();
  const printStockUnlimited = useReactToPrint({
    content: () => printStockRefUnlimited.current,
  });

  // Print Price Functionality
  const printPriceRefLimited = useRef();
  const printPriceLimited = useReactToPrint({
    content: () => printPriceRefLimited.current,
  });
  const printPriceRefUnlimited = useRef();
  const printPriceUnlimited = useReactToPrint({
    content: () => printPriceRefUnlimited.current,
  });

  return (
    <div className="kelolaproduk">

      {editProductState[0] === 1 ?
        <PopupWindow innerCard={
          <div className="editProductCard">
            <div className="cardHeading">
              <h5>Ubah Harga Jual</h5> 
              <h6>{editProductState[1].format_p_name}</h6>
            </div>
            <form onSubmit={confirmEditPrice}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "45%" }}>
                  <label htmlFor="edit_s_price">Harga Jual <span className="reqStar">*</span></label><br />
                  <div style={{ position: "absolute", fontSize: "18px", top: "34.4px", left: "21px", userSelect: "none", zIndex: "-1"}}>Rp</div>
                  <input 
                    id="edit_s_price" 
                    type="text" 
                    name="s_price"
                    onChange={handleEditFormChange} 
                    value={editProductPrice.format_s_price} 
                    style={{ padding: "0.8em 20px 0.6em 50px", backgroundColor: "transparent" }}
                  /> 
                </div>
                <div style={{ width: "55%" }}>
                  <label htmlFor="edit_s_price_b">Harga Jual Diskon</label><br />
                  <div style={{ position: "absolute", fontSize: "18px", top: "34.4px", left: "21px", userSelect: "none", zIndex: "-1"}}>Rp</div>
                  <input 
                    id="edit_s_price_b" 
                    type="text" 
                    name="s_price_b"
                    onChange={handleEditFormChange} 
                    value={editProductPrice.format_s_price_b} 
                    style={{ padding: "0.8em 20px 0.6em 50px", backgroundColor: "transparent" }}
                  /> 
                </div>
              </div>

              <div className="cardButtons">
                <Button 
                  className="editProduct-cancel"
                  iconClasses="bi bi-x-circle" 
                  btnText="Batal" 
                  clickHandler={(click) => { click.preventDefault(); setEditProductState([0, {}]); }} 
                  type="button" 
                />
                <Button 
                  className="editProduct-confirm"
                  iconClasses="bi bi-check-circle" 
                  btnText="Konfirmasi" 
                  clickHandler={confirmEditPrice} 
                  type="submit" 
                />
              </div>
            </form>
          </div>
        } />
      : ""}

      <h3 className="tabHeading">Kelola Produk</h3>
      <div className="controlFlex">
        {addProductWindow ?
          <PopupWindow innerCard={
            <TambahBarang 
              refreshProducts={refreshProductList} 
              closePopup={() => { setAddProductWindow(false) }}
            />
          } />
        : ""}
        {userBinding.perms.includes(permsBinding["view-product-details"]) ?
          <Button 
            className="openAddProductBtn"
            iconClasses="bi bi-plus-square" 
            btnText="Produk Baru" 
            clickHandler={(clickEvt) => {
              clickEvt.preventDefault();
              setAddProductWindow(true)
            }} 
            type="button" 
          /> 
        : ""}
        <div className="d-flex ms-auto me-3 align-items-center">
          <Toggler options={[
            ["By Name", searchBy === "format_p_name", () => { setSearchBy("format_p_name"); }],
            ["By Ingredients", searchBy === "ingredients", () => { setSearchBy("ingredients"); }]
          ]} style={{ height: "48px" }} />
        </div>
        <input 
          id="searchProductsInput" 
          placeholder="Cari Produk"
          value={searchProductsText}
          onChange={(changeEvt) => { setSearchProductsText(changeEvt.target.value); }}
          style={{ height: "48px" }}
        />
        
      </div>

      <div className="productHeadingFlex mb-3">
        <div style={{ marginRight: "auto" }}>
          <h5 className="sectionHeading" style={{ marginBottom: "0" }}>Daftar Produk</h5>
        </div>

        <Dropdown 
          selectorFn={() => { setCurrentDropdown((prev) => prev === "print" ? null : "print") }}
          selected={currentDropdown === "print"}
          selectorText={
            <>
            <i className="bi bi-printer" style={{ marginRight: "0.5em" }}></i>
            Print
            </>
          }
          content={
            <div className="dropdown-content d-flex flex-row gap-5">
              <div className="d-flex flex-column gap-3 text-nowrap">
                <div style={{ padding: "calc(0.6em + 1px) 0" }}>Stock and Expiry</div>
                <div style={{ padding: "calc(0.6em + 1px) 0" }}>Sale Price</div>
              </div>
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-row gap-2">
                  <Button 
                    className="cetakStockCheckBtn text-nowrap"
                    iconClasses="" 
                    btnText={`Current ${processedDataFilters[1]}`} 
                    clickHandler={printStockLimited} 
                    type="button" 
                  />
                  <Button 
                    className="cetakStockCheckBtn text-nowrap"
                    iconClasses="" 
                    btnText={`All Items`} 
                    clickHandler={printStockUnlimited} 
                    type="button" 
                  />
                </div>
                <div className="d-flex flex-row gap-2">
                  <Button 
                    className="cetakStockCheckBtn text-nowrap"
                    iconClasses="" 
                    btnText={`Current ${processedDataFilters[1]}`} 
                    clickHandler={printPriceLimited} 
                    type="button" 
                  />
                  <Button 
                    className="cetakStockCheckBtn text-nowrap"
                    iconClasses="" 
                    btnText={`All Items`} 
                    clickHandler={printPriceUnlimited} 
                    type="button" 
                  />
                </div>
              </div>
            </div>
          }
          expandDirection="center"
        />

        <Dropdown 
          selectorFn={() => { setCurrentDropdown((prev) => prev === "settings" ? null : "settings") }}
          selected={currentDropdown === "settings"}
          selectorText={
            <>
            <i className="bi bi-gear" style={{ marginRight: "0.5em" }}></i>
            Settings
            </>
          }
          content={
            <div className="dropdown-content d-flex flex-row gap-5">
              <div className="d-flex flex-column gap-3 text-nowrap">
                <div style={{ padding: "calc(0.6em + 1px) 0" }}>Sort By</div>
                <div style={{ padding: "calc(0.6em + 1px) 0" }}>Filter by Status</div>
                <div style={{ padding: "calc(0.6em + 1px) 0" }}>Limit Results</div>
              </div>
              <div className="d-flex flex-column gap-3">
                <Toggler options={[
                  ["ID", processedDataFilters[2] === 0, () => { setSortFilter(0); }],
                  ["Produk", processedDataFilters[2] === 1, () => { setSortFilter(1); }],
                  ["Stock", processedDataFilters[2] === 3, () => { setSortFilter(3); }],
                  ["Expiry", processedDataFilters[2] === 2, () => { setSortFilter(2); }],
                ]}/>
                <Toggler options={[
                  ["Enabled", processedDataFilters[0] === 1, () => { setEnableFilter(1); }],
                  ["Disabled", processedDataFilters[0] === 0, () => { setEnableFilter(0); }],
                  ["Both", processedDataFilters[0] === 2, () => { setEnableFilter(2); }]
                ]}/>
                <Toggler options={[
                  ["250", processedDataFilters[1] === 250, () => { setAmountFilter(250); }],
                  ["1000", processedDataFilters[1] === 1000, () => { setAmountFilter(1000); }]
                ]}/>
              </div>
            </div>
          }
          expandDirection="left"
          expandOffset="161px"
        />

        <Toggler options={[
          [<i className="bi bi-arrow-left" />, false, () => { 
            if (processedDataFilters[3] != 0)
              setProcessedDataFilters((old) => [old[0], old[1], old[2], old[3] - 1])
          }],
          [<i className="bi bi-arrow-right" />, false, () => { 
            if ((processedDataFilters[1] * (processedDataFilters[3] + 1)) < availDataCount)
              setProcessedDataFilters((old) => [old[0], old[1], old[2], old[3] + 1])
          }]
        ]} style={{ fontSize:"24px", padding: "0.3em 1em" }} optionsClass="tableNavOption" />

      </div>
      
      <Table 
        format={[
          ["ID", "pid", "5%"], 
          ["Produk", "format_p_name", userBinding.perms.includes(permsBinding["view-product-details"]) ? "42%" : "56%"],
          [userBinding.perms.includes(permsBinding["view-product-details"]) ? "Harga Beli" : "", userBinding.perms.includes(permsBinding["view-product-details"]) ? "bprice" : "", userBinding.perms.includes(permsBinding["view-product-details"]) ? "13%" : "0%"],
          ["Harga Jual", "s_price_display", "13%"],
          ["Stok", "stock", "9%"],
          ["ED Tercepat", "ed", "10%"],
          ["Kelola", "mgtBtn", userBinding.perms.includes(permsBinding["view-product-details"]) ? "8%" : "5%"]
        ]} 
        dataArray={finalArr}
        xScroll
        yScroll
      />

      <div style={{ display: "none" }}> {/* Printed Section */}
        <div style={{ padding: "2em" }} ref={printStockRefLimited}>
          <h3 className="tabHeading" style={{ marginTop: "0.35em", marginBottom: "0.5em" }}>Stok Aktif</h3>
          <Table
            format={[
              ["ID", "pid", "8%"], 
              ["Produk", "format_p_name", "57%"],
              ["Stok", "stock", "17%"],
              ["ED Tercepat", "ed", "18%"],
            ]} 
            dataArray={finalArr}
          />
        </div>
        <div style={{ padding: "2em" }} ref={printStockRefUnlimited}>
          <h3 className="tabHeading" style={{ marginTop: "0.35em", marginBottom: "0.5em" }}>Stok Aktif</h3>
          <Table
            format={[
              ["ID", "pid", "8%"], 
              ["Produk", "format_p_name", "57%"],
              ["Stok", "stock", "17%"],
              ["ED Tercepat", "ed", "18%"],
            ]} 
            dataArray={unlimitedDataArray}
          />
        </div>
        <div style={{ padding: "2em" }} ref={printPriceRefLimited}>
          <h3 className="tabHeading" style={{ marginTop: "0.35em", marginBottom: "0.5em" }}>Stok Aktif</h3>
          <Table
            format={[
              ["ID", "pid", "8%"], 
              ["Produk", "format_p_name", "67%"],
              ["Harga Jual", "s_price_display", "25%"],
            ]} 
            dataArray={finalArr}
          />
        </div>
        <div style={{ padding: "2em" }} ref={printPriceRefUnlimited}>
          <h3 className="tabHeading" style={{ marginTop: "0.35em", marginBottom: "0.5em" }}>Stok Aktif</h3>
          <Table
            format={[
              ["ID", "pid", "8%"], 
              ["Produk", "format_p_name", "67%"],
              ["Harga Jual", "s_price_display", "25%"],
            ]} 
            dataArray={unlimitedDataArray}
          />
        </div>
      </div>

    </div>
  )
}