import { useContext, useState } from "react"

import { OptionsContext } from "../../Contexts";
import { useFunctionsFetch } from "../../UtilFunctions";
import PopupWindow from "../../Utilities/PopupWindow/popupwindow";
import Button from "../../Utilities/Button/button";

import "./customer.css";
import TambahCustomer from "./tambahcustomer";

export default function Customer() {
  const optionsBinding = useContext(OptionsContext);

  // Active Popup Controller State
  const [currentPopup, setCurrentPopup] = useState(null);

  // Current Customer Functionality
  const [customerSearchData, setCustomerSearchData] = useState({
    tel: ""
  })
  const [customerData, setCustomerData] = useState(null);
  const [submitFindCustomer, setSubmitFindCustomer] = useState(0);
  useFunctionsFetch(
    undefined,
    "/find-customer",
    customerSearchData,
    optionsBinding["manage-customers"],
    submitFindCustomer,
    (res) => {
      setSubmitFindCustomer(0);
      if (res !== null) {
        setCustomerData(res)
      } else setCustomerData(null)
    }
  )

  function customerSearchEditHandler(changeEvt) {
    if (!/^(0[\d]{0,14})?$/.test(changeEvt.target.value)) {
      return
    } 
    setCustomerSearchData((prev) => ({
      ...prev,
      [changeEvt.target.name]: changeEvt.target.value
    }))
  }

  function findCustomerHandler(submitEvt) {
    submitEvt.preventDefault();
    setSubmitFindCustomer(1)
  }


  // Rendered Component
  return (
    <div className="customerTab">
      {/* Heading Section */}
      <div className="headingFlex">
        <div>
          <h3 className="tabHeading">Customer</h3> 
        </div>
        <div>
          <Button 
            className="addCustomerBtn"
            iconClasses="bi bi-person-plus" 
            btnText="Tambah Customer" 
            clickHandler={(clickEvt) => {
              clickEvt.preventDefault();
              setCurrentPopup('add-customer');
            }} 
            type="button"
          />
        </div>
      </div>

      {/* Add Customer Popups */}
      {(currentPopup && currentPopup === 'add-customer') &&
        <PopupWindow innerCard={
          <TambahCustomer 
            callerTab={optionsBinding["manage-customers"]}
            closePopup={() => { setCurrentPopup(null) }}
            successPopup={() => { setCurrentPopup('add-customer-success') }}
          />
        } />
      }
      {(currentPopup && currentPopup === 'add-customer-success') &&
        <PopupWindow message={[
          "Berhasil", 
          "Customer baru telah berhasil didaftarkan kepada sistem!"
        ]} closePopup={() => { setCurrentPopup(null) }} />
      }

      {/* Find Customer Section */}
      <h5 className="sectionHeading mb-4">Cari Customer</h5>

      <form onSubmit={findCustomerHandler}>
        <div>
          <div style={{ flexGrow: "1"}}>
            <label htmlFor="customer_phone">
              Nomor Telepon Customer <span className="reqStar">*</span>
            </label>
            <br />
            <input 
              id="customer_phone" 
              type="text" 
              name="tel"
              value={customerSearchData.tel}
              onChange={customerSearchEditHandler}
              placeholder="0XXXXXXXXXXX"
            /> 
          </div>
          <div>
            <Button 
              className="searchCustomerBtn"
              iconClasses="bi bi-search" 
              btnText="Cari Customer" 
              clickHandler={findCustomerHandler} 
              type="submit"
            />
          </div>
        </div>
      </form>

      <div className="customer-data-section">
        <h3>Hasil Pencarian Customer</h3>
        <div className={`customer-data-table ${customerData ? customerData.typ : ""}`}>
          <div>
            <i className="bi bi-person-circle"></i>
          </div>
          <div className="customer-data-table-info">
            <div className="customerNameStrip">
              <p className="customerName">{customerData ? customerData.name : "Tidak Ada Customer"}</p>
              <div className="badge regular-badge">Regular</div> 
              <div className="badge vip-badge">VIP</div>
            </div>
            <div className="customerInfoStrip">
              <p className="customerTel">{customerData ? customerData.tel : ""}</p>
              {customerData && customerData.email && 
                <>
                <p className="infoStripSeparator">|</p>
                  <p className="customerEmail">{customerData.email}</p>
                </>
              }
            </div>
            {customerData && customerData.addr && 
              <>
                <hr />
                <p className="customerInfoDescriptor">Alamat</p>
                <p className="customerGender">{customerData.addr}</p>
              </>
            }

          </div>
        </div> 
      </div>


    </div>
  )
}