import { useState, useEffect } from 'react';
import './App.css';

import { MinuteContext, UserContext } from "../Contexts";
import ClientPanel from '../MainContent/clientpanel';
import LoginPanel from '../Login/loginpanel';
import ErrorPanel from '../Error/errorpanel';


export default function App() {
  const activityMs = 3600000;
  const [currentUser, setCurrentUser] = useState({ auth: 0 });
  const [minute, setMinute] = useState(new Date().getMinutes());
  const [logoutTimer, setLogoutTimer] = useState(null);

  useEffect(() => {
    let nextMin = new Date().toISOString().split(':');
    nextMin[1] = (parseInt(nextMin[1]) + 1).toString().padStart(2, '0');
    if (nextMin[1] === '60') nextMin[1] = '00';
    nextMin[2] = '00.010Z';
    setTimeout(() => {
      setMinute(new Date().getMinutes());
      setInterval(() => {
        setMinute(new Date().getMinutes());
      }, 60000)
    }, Date.parse(nextMin.join(':')) - Date.now());
  }, []);

  useEffect(() => {
    if (currentUser.auth === 1) {
      if (!logoutTimer) {
        let timer = setTimeout(() => { 
          setLogoutTimer(null);
          resetUser(); 
        }, (currentUser.expiresAt - Date.now()))
        setLogoutTimer(timer)
      }
    } 
  }, [ currentUser ])

  let lastActivityTimestamp = Date.now();
  let activityInterval = null;

  const handleUserActivity = () => {
    lastActivityTimestamp = Date.now();
  };

  const checkInactivity = () => {
    const timeSinceLastActivity = Date.now() - lastActivityTimestamp;
    if ((timeSinceLastActivity >= activityMs) && currentUser.auth === 1) {
      resetUser();
    }
  };

  useEffect(() => {
    checkInactivity();
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);
    activityInterval =  setInterval(checkInactivity, 1000);

    return () => {
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      clearInterval(activityInterval);
    };
  }, [currentUser]);


  async function resetUser(errParam, lastTab) {
    if (errParam === undefined) {
      try {
        await fetch(
          `${process.env.REACT_APP_AUTH_SERVER}/expire-accessJWT`, 
          {method: 'POST', credentials: 'include'
        });
      } catch (fetchError) {
        console.log("Failed to expire JWT");
      } finally {
        setCurrentUser({ auth: 0 });
      }
    
    } else {
      if (lastTab !== undefined) {
        setCurrentUser((prevUser) => ({ 
          ...prevUser,
          auth: 2, 
          err: errParam,
          lastTab: lastTab
        }));
      } else {
        setCurrentUser((prevUser) => ({ 
          ...prevUser,
          auth: 2, 
          err: errParam
        }));
      }
  
    }
  }

  return (
    <MinuteContext.Provider value={minute}>
      <UserContext.Provider value={[currentUser, setCurrentUser, resetUser]}> {      
        (currentUser.auth === 0) ?
          <LoginPanel /> : 
        (currentUser.auth === 1) ? 
          <ClientPanel 
            currentUser={currentUser} 
            resetUser={resetUser} 
            lastTab={currentUser.lastTab} 
          /> : 
        (currentUser.auth === 2) ? 
          <ErrorPanel 
            error={currentUser.err} 
            setCurrentUser={setCurrentUser} 
            resetUser={resetUser} 
          /> : ""
      } </UserContext.Provider>
    </MinuteContext.Provider>
  )
}