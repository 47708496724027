import {useContext, useEffect, useState} from 'react';
import './loginpanel.css';
import Button from '../Utilities/Button/button';
import pcLogo from '../../assets/images/pc-apotek-logo.svg';
import { UserContext } from '../Contexts';

export default function LoginPanel() {
  let [, setCurrentUser] = useContext(UserContext)

  let [formData, setFormData] = useState({
    username: "",
    password: ""
  });
  let [displayStates, setDisplayStates] = useState([0, "", 0]);
  let [formSpacerHeight, setFormSpacerHeight] = useState(0);

  function updateDisplayStates(args) {
    setDisplayStates(args);
  }

  useEffect(() => {
    recalculateSpacer();
  }, [ displayStates ])

  useEffect(() => {
    window.addEventListener('resize', recalculateSpacer)
    updateDisplayStates([0, "", 1]);

    async function attemptReconnect() {
      try {
        let serverResponse = await fetch(`${process.env.REACT_APP_AUTH_SERVER}/check-refresh`, {
          method: 'POST',
          credentials: 'include'
        });
  
        if (serverResponse.status == 200) {
          let serverResponseData = await serverResponse.json();
          setCurrentUser({ 
            ...serverResponseData,
            auth: 1
          });
        }
  
        updateDisplayStates([0, "", 0]);
      } catch (err) {
        updateDisplayStates([3, "Error! Tidak dapat terkoneksi dengan server!", 0]);
      }

    }; attemptReconnect();

    return () => {
      window.removeEventListener('resize', recalculateSpacer);
    }
  }, [])

  function handleFormChange(changeEvent) {
    setFormData((prevData) => ({
      ...prevData,
      [changeEvent.target.name]: changeEvent.target.value
    }));
  }

  async function handleFormSubmit(submitEvent) {
    submitEvent.preventDefault();
    updateDisplayStates([0, "", 1])
    
    let serverResponse;
    try {
      serverResponse = await fetch(`${process.env.REACT_APP_AUTH_SERVER}/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({credentials: formData})
      });
    } catch (err) {
      serverResponse = null;
    }

    if (serverResponse == null || serverResponse.status == 500) {
      updateDisplayStates([3, "Error! Tidak dapat terkoneksi dengan server!", 0])
      return
    }

    let serverResponseData = await serverResponse.json();

    if (serverResponse.status == 200) {
      updateDisplayStates([0, "", 0])
      setCurrentUser({ 
        ...serverResponseData,
        auth: 1
      });

    } else if (serverResponse.status == 401) {
      if (serverResponseData.err == "username") {
        updateDisplayStates([1, "Username tidak ditemukan! Mohon memastikan username yang telah di ketik benar.", 0])
      } else if (serverResponseData.err == "password") {
        updateDisplayStates([2, "Password tidak benar! Mohon memastikan password yang telah di ketik benar.", 0])
      }
    } else {
      console.log(serverResponse)
    }

  }

  function recalculateSpacer() {
    try {
      setFormSpacerHeight(
        (((window.innerHeight - document.querySelector(".login-formWrapper").clientHeight) / 2) >= 12) ? 
        `${((window.innerHeight - document.querySelector(".login-formWrapper").clientHeight) / 2)}px` :
        '12px'
        )
    } catch (err) {
      setFormSpacerHeight('10vh');
    }

  }

  return (
    <div className='loginPage'>
      <div style={{height: `${formSpacerHeight}`}}></div>
      <div className='login-formWrapper'>
        <img src={pcLogo} />
        <h3>Management Login</h3>
        <form>
          <label htmlFor='inp-un' style={{ color: `${(displayStates[0] == 1) ? 'red' : 'black'}`}}>Username</label>
          <input 
            type="text" 
            name="username" 
            id="inp-un" 
            onChange={handleFormChange}
            value={formData.username}
            style={{ borderColor: `${(displayStates[0] == 1) ? 'red' : 'black'}`}}
          />
          <p style={{display: `${(displayStates[0] == 1) ? 'block' : 'none'}`}} className="loginErrorMsg">
            {displayStates[1]}
          </p>

          <label htmlFor='inp-pw' style={{ color: `${(displayStates[0] == 2) ? 'red' : 'black'}`}}>Password</label>
          <input 
            type="password" 
            name="password" 
            id="inp-pw" 
            onChange={handleFormChange}
            value={formData.password}
            style={{ borderColor: `${(displayStates[0] == 2) ? 'red' : 'black'}`}}
          />
          <p style={{display: `${(displayStates[0] == 2) ? 'block' : 'none'}`}} className="loginErrorMsg">
            {displayStates[1]}
          </p>

          <p style={{display: `${(displayStates[0] == 3) ? 'block' : 'none'}`}} className="criticalErrorMsg">
            {displayStates[1]}
          </p>

          <div className='loginSubmit-flex'>
            <Button iconClasses="bi bi-box-arrow-right" btnText="Login" clickHandler={handleFormSubmit} type="submit" />
            <div className='lds-dual-ring' style={{opacity: `${displayStates[2]}`}}></div>
          </div>

        </form>
      </div>
      <div style={{height: `${formSpacerHeight}`}}></div>
    </div>
  );
}