import { useEffect, useState } from "react";
import { findMatchesArrayObject } from "../../UtilFunctions";
import "./search.css";

export default function useSearchProvider(sourceArray, dbObjectKey, formSetter, formFieldName, limit = undefined, rawReturn = false) {
  const [searchResultsObject, setSearchResultsObject] = useState([]);
  const [searchResultsMapped, setSearchResultsMapped] = useState([]);
  const [cutoffStart, setCutoffStart] = useState(0);
  const [arrowPosition, setArrowPosition] = useState(-1);

  function selectHandler(idx = undefined) {
    let listObject = (idx !== undefined) ? searchResultsObject[idx] : searchResultsObject[arrowPosition]
    formSetter((prevData) => ({
      ...prevData,
      [formFieldName]: (rawReturn === true) ? listObject : listObject[dbObjectKey]
    }));
    setSearchResultsObject([])
    clearSearch()
  }

  function clearSearch() {
    setSearchResultsObject([])
  }

  function keydownHandler(keyEvt) {
    if (
      (keyEvt.code === "ArrowDown") 
      || (keyEvt.code === "ArrowUp")
      || (keyEvt.code === "Enter")
      || (keyEvt.code === "NumpadEnter")
    ) { keyEvt.preventDefault(); }

    if ((keyEvt.code === "ArrowDown") && (arrowPosition < (searchResultsMapped.length - 1))) {

      if (arrowPosition < cutoffStart + (limit ? limit : Infinity) - 1) {
        setArrowPosition((prev) => { return prev + 1 });
      } else if (arrowPosition === cutoffStart + (limit ? limit : Infinity) - 1) {
        setCutoffStart((prev) => { return prev + 1 });
        setArrowPosition((prev) => { return prev + 1 });
      }
    
    } else if ((keyEvt.code === "ArrowUp") && (arrowPosition > 0)) {
      
      if (arrowPosition > cutoffStart) {
        setArrowPosition((prev) => { return prev - 1 });
      } else if (arrowPosition === cutoffStart) {
        setCutoffStart((prev) => { return prev - 1 });
        setArrowPosition((prev) => { return prev - 1 });
      }
      
    } else if (((keyEvt.code === "Enter") || (keyEvt.code === "NumpadEnter")) && (arrowPosition !== -1)) {
      selectHandler()
    }
  } 

  function handleWheel(wheelEvt) {
    console.log(document.querySelector("html").scrollTop)
    if ((wheelEvt.deltaY === 100) && ((cutoffStart + limit) < searchResultsMapped.length)) {
      setCutoffStart((prev) => { return prev + 1 });
    } else if ((wheelEvt.deltaY === -100) && (cutoffStart > 0)) {
      setCutoffStart((prev) => { return prev - 1 });
    }
  }

  function querySearch(word) {
    setCutoffStart(0);
    setSearchResultsObject(findMatchesArrayObject(word.split(" "), sourceArray, dbObjectKey));
  }

  useEffect(() => {
    if (searchResultsObject.length > 0) { setArrowPosition(0); }
  }, [ searchResultsObject ])

  useEffect(() => {
    setSearchResultsMapped(searchResultsObject.map((listObject, idx) => 
      <div 
        key={listObject[dbObjectKey]}
        className={`searchListing ${arrowPosition === idx ? 'selectedListing' : ''}`} 
        onMouseDown={() => {selectHandler(idx)}}
        onMouseOver={() => {setArrowPosition(idx)}}
        onWheel={handleWheel}
      >
        {listObject[dbObjectKey]}
      </div>
    ))
  }, [ searchResultsObject, arrowPosition ])
  
  return [
    (limit) ? searchResultsMapped.slice(cutoffStart, cutoffStart + limit) : searchResultsMapped, 
    clearSearch, 
    querySearch,
    keydownHandler
  ]
}