export default function TableRow(props) {
  let mappedData = props.columns.map((column, idx) => 
    <div 
      className="customTable-columnData" 
      style={{ width: `calc(${column[0]} - 0.75em)` }}
      key={idx}
    >
      {column[1]}
    </div>
  );

  return (
    <div 
      className={`tableRow${props.ending ? ' endingRow' : ''}${props.additionalClass ? ` ${props.additionalClass}` : ''}`}
      style={props.customStyle}
    >
      {mappedData}
    </div>
  )
}