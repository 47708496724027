import { useContext, useEffect, useState } from "react";
import { UserContext } from "./Contexts";

export function useFunctionsFetch(defValue, url, reqBody = {}, lastTab, immediate = 1, finishCallback = () => {}) {
  let [currentUser,, resetUserF] = useContext(UserContext)
  let [res, setRes] = useState(undefined);

  let serverResponse;

  async function runFetch() {
    try {
      serverResponse = await fetch(`${process.env.REACT_APP_FUNCTIONS_SERVER}${url}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(reqBody)
      })
  
      if ((`${serverResponse.status}`[0] === '2') || (serverResponse.status === 400)) {
        let textResponse = await serverResponse.text()
        try {
          let jsonData = JSON.parse(textResponse)
          finishCallback(jsonData);
          setRes(jsonData);
        } catch (err) {
          console.log(err)
          finishCallback(textResponse);
          setRes(textResponse);
        }
      } else if (serverResponse.status === 403) {
        resetUserF("no-permissions", lastTab)
        setRes(defValue);
      } else if (serverResponse.status === 500) {
        resetUserF("functions-unavailable", lastTab)
        setRes(defValue);
      }
  
    } catch (err) {
      resetUserF("functions-unavailable", lastTab)
      setRes(defValue);
    }

  }; 

  useEffect(() => {
    if (immediate) {
      runFetch();
    }
  }, [ immediate ])

  return (res !== undefined) ? res : defValue;
  
}



export function findMatchesArrayObject(searchArray, sourceArray, sourceKey) {
  let resultArray = [];

  for (let index = 0; index < sourceArray.length; index++) {
    let sourceValue = sourceArray[index][sourceKey].toLowerCase();
    let match = true;

    for (let searchIndex = 0; searchIndex < searchArray.length; searchIndex++) {
      if (sourceValue.includes(searchArray[searchIndex].toLowerCase()) == false) {
        match = false;
      }
    }

    if (match == true) {
      resultArray.push(sourceArray[index]);
    }
  }
  return resultArray;

}

export function formatCurrency(purenumber) {
  purenumber = `${purenumber}`
  let isNeg = purenumber[0] === '-';
  if (isNeg) purenumber = purenumber.slice(1, );
  if (parseFloat(purenumber) >= 1000) {
    let formatCurrencyString = "";
    let remainingPrice = `${purenumber}`;
    let rear = null;
    let decimalIdx = remainingPrice.indexOf(".");
    if (decimalIdx != -1) {
      rear = remainingPrice.slice(decimalIdx);
      remainingPrice = remainingPrice.slice(0, decimalIdx);
    }
    while ((remainingPrice.length - 3) > 0) {
      formatCurrencyString = `,${remainingPrice.slice(-3)}` + formatCurrencyString
      remainingPrice = remainingPrice.slice(0, -3);
    }
    formatCurrencyString = `${remainingPrice}${formatCurrencyString}`;
    if (rear) formatCurrencyString += rear;
    return `${isNeg ? '-' : ''}${formatCurrencyString}`;
  } else return `${isNeg ? '-' : ''}${purenumber}`;
}