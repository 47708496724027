import "./button.css"

export default function Button(props) {
  return (
    <button 
      type={props.type ? props.type : "button"} 
      onClick={props.clickHandler} 
      className={`customButton ${props.className ? props.className : ""}`}
      disabled={props.disabled ? true : false}
    >
      <i className={props.iconClasses}></i>
      <span>{props.btnText}</span>
    </button>
  )
}