import Button from "../Button/button"
import "./popupwindow.css"

export default function PopupWindow(props) {
  return (
    <div className="popupWindowSection">
      <div className="popupWindowGray"></div>
      {
        props.message ? 
        <div className="messagePopup">
          <h4>{props.message[0]}</h4>
          <p className="mb-5">{props.message[1]}</p>
          <Button 
            className="messagePopup-ack mb-0 ms-auto"
            iconClasses="bi bi-check-circle" 
            btnText="OK" 
            clickHandler={props.closePopup} 
            type="button" 
          />
        </div> 
        : props.innerCard
      }
    </div>
  )
}