import {useContext, useState} from 'react';

import { UserContext, OptionsContext, SidebarContext } from '../Contexts';
import SidebarOption from './sidebar-option';
import "./sidebar.css";


export default function Sidebar(props) {
  const binding = useContext(OptionsContext)
  const [user] = useContext(UserContext)
  let [sidebarCondition, setSidebarCondition] = useState("close");
  let [currentDD, setCurrentDD] = useState(null);

  function closeSideBar() {
    setSidebarCondition("close");
    setCurrentDD(null);
  }

  function openSideBar() {
    setSidebarCondition("open");
    setCurrentDD(null);
  }

  function handleNewDD(caller) {
    setCurrentDD((prevDD) => (JSON.stringify(prevDD) == JSON.stringify(caller)) ? null : caller);
  }

  function handleNewActive(caller) {
    props.setActiveTab(caller);
    closeSideBar();
  }

  function checkAllowed(optionNames) {
    if (!optionNames) return false;
    let permsNeeded = getTabNums(optionNames)
    return (permsNeeded.filter((perm) => user.perms.includes(perm)).length > 0) ? true : false;
  }

  function getTabNums(optionNames) {
    if (!optionNames) return [];
    return optionNames.map((optionName) => binding[optionName])
  }

  return (
    <SidebarContext.Provider value={[sidebarCondition, props.activeTab, handleNewActive, openSideBar, currentDD, handleNewDD]}>
      <aside className={`sidebar ${sidebarCondition}`} onMouseEnter={openSideBar} onMouseLeave={closeSideBar}>
        <div className="sidebarContent">
          {checkAllowed(["beranda"]) ? 
            <SidebarOption 
              name="Beranda" 
              iconClass="bi bi-house-door"   
              tabNo={getTabNums(["beranda"])}
            /> 
          : ""}
          {checkAllowed(["kasir"]) ? 
            <SidebarOption 
              name="Kasir (POS)" 
              iconClass="bi bi-cash"  
              tabNo={getTabNums(["kasir"])}
            /> 
          : ""}
          {checkAllowed(["manage-customers"]) ? 
            <SidebarOption 
              name="Customer" 
              iconClass="bi bi-person-square"  
              tabNo={getTabNums(["manage-customers"])}
            /> 
          : ""}
          {checkAllowed([
            "add-purch",
            "return-purch"
          ]) ? 
            <SidebarOption 
              name="Pembelian" 
              iconClass="bi bi-cart"  
              tabNo={getTabNums([
                "add-purch",
                "return-purch"
              ])}
              dropdown={[
                checkAllowed(["add-purch"]) ? {name: "Tambah Pembelian Baru", tabNo: getTabNums(["add-purch"])} : null,
                checkAllowed(["return-purch"]) ? {name: "Retur Pembelian", tabNo: getTabNums(["return-purch"])} : null
              ]} 
            /> 
          : ""}
          {checkAllowed(["manage-items"]) ? 
            <SidebarOption 
              name="Inventory" 
              iconClass="bi bi-archive"  
              tabNo={getTabNums(["manage-items"])}
            /> 
          : ""}
          {checkAllowed([
            "manage-accounts", 
            "create-voucher", 
            "view-journal",
            "view-ledger",
            "view-profitloss",
            "view-balancesheet",
          ]) ? 
            <SidebarOption 
              name="Accounting" 
              iconClass="bi bi-calculator"  
              tabNo={getTabNums([
                "manage-accounts", 
                "create-voucher", 
                "view-journal",
                "view-ledger",
                "view-profitloss",
                "view-balancesheet",
              ])}
              dropdown={[
                checkAllowed(["manage-accounts"]) ? {name: "Kelola Accounts", tabNo: getTabNums(["manage-accounts"])} : null, 
                checkAllowed(["create-voucher"]) ? {name: "Buat Voucher", tabNo: getTabNums(["create-voucher"])} : null, 
                checkAllowed(["view-journal"]) ? {name: "Lihat Journal", tabNo: getTabNums(["view-journal"])} : null, 
                checkAllowed(["view-ledger"]) ? {name: "General Ledger", tabNo: getTabNums(["view-ledger"])} : null, 
                checkAllowed(["view-profitloss"]) ? {name: "Profit & Loss", tabNo: getTabNums(["view-profitloss"])} : null,
                checkAllowed(["view-balancesheet"]) ? {name: "Balance Sheet", tabNo: getTabNums(["view-balancesheet"])} : null
              ]} 
            /> 
          : ""}
          {checkAllowed(["report-general", "report-purchase", "report-sales", "report-ed"]) ? 
            <SidebarOption 
              name="Laporan" 
              iconClass="bi bi-file-bar-graph"  
              tabNo={getTabNums(["report-general", "report-purchase", "report-sales", "report-ed"])}
              dropdown={[
                checkAllowed(["report-general"]) ? {name: "Laporan Umum", tabNo: getTabNums(["report-general"])} : null,
                checkAllowed(["report-purchases"]) ? {name: "Laporan Pembelian", tabNo: getTabNums(["report-purchases"])} : null, 
                checkAllowed(["report-sales"]) ? {name: "Laporan Penjualan", tabNo: getTabNums(["report-sales"])} : null,
                checkAllowed(["report-ed"]) ? {name: "Laporan ED Produk", tabNo: getTabNums(["report-ed"])} : null
              ]} 
            /> 
          : ""}
          {checkAllowed(["logout"]) ? 
            <SidebarOption 
              name="Logout" 
              iconClass="bi bi-box-arrow-left"  
              tabNo={getTabNums(["logout"])}
            /> 
          : ""}

        </div>
      </aside>
    </SidebarContext.Provider>
  );
};